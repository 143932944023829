import Divider from "shared-components/divider";
import { SnapIcon, SnapInput, SnapSelectMenu, SnapLink } from "suit";
import { staffInfo } from "types/programs";
import { hasStaffErrorsType } from "..";
type AddStaffProps = {
  newStaff: staffInfo[];
  setNewStaff: React.Dispatch<React.SetStateAction<staffInfo[]>>;
  hasErrors: hasStaffErrorsType[];
  setHasErrors: React.Dispatch<React.SetStateAction<hasStaffErrorsType[]>>;
};
function AddStaff({
  newStaff,
  setNewStaff,
  hasErrors,
  setHasErrors,
}: AddStaffProps) {
  const handleNewStaff = (
    value: string,
    valToUpdate: string,
    staff: staffInfo,
    idx: number
  ) => {
    let newStaffToAdd: staffInfo = { ...staff };
    let tempStaffList = [...newStaff];
    switch (valToUpdate) {
      case "First":
        newStaffToAdd.firstName = value;
        break;
      case "Last":
        newStaffToAdd.lastName = value;
        break;
      case "Email":
        newStaffToAdd.email = value;
        break;
      case "Role":
        newStaffToAdd.type =
          value === "Group Staff"
            ? "group_staff"
            : value === "Group Observer"
            ? "group_observer"
            : "";
        break;
    }
    tempStaffList.splice(idx, 1, newStaffToAdd);
    setNewStaff(tempStaffList);
  };

  return (
    <div className="modal-card">
      <p>
        You can recruit group-level assistants and observers to help manage your
        group. Add names and email addresses below, and send out invites.
        Invitees must sign up in order to activate their user privileges. (Sign
        up is quick and easy.)
      </p>
      <ul className="mt-4 pl-6 list-disc">
        <li>
          Assistants only see data for the groups they are invited to, and they
          can perform all functions for that group.
        </li>
        <li>
          Observers can only see data for the group they are invited to, but
          they cannot access group funds or edit group information.
        </li>
        <li>
          Group-level users of either type cannot add or delete groups, and they
          cannot change the organization-wide notification preferences.{" "}
          <SnapLink
            text="Learn more about group-level users."
            href="https://helpdesk.snapraise.com/snap-spend#group-staff-training-courses"
            target="_blank"
            className="text-blue-600 font-bold"
          />
        </li>
      </ul>
      {newStaff.map((staff, idx) => {
        return (
          <div key={idx}>
            <div className="flex justify-between first:mt-12">
              <p>Staff Information {idx + 1}</p>
              <SnapIcon
                icon="trash-solid"
                size="sm"
                color={newStaff.length > 1 ? "blue" : "gray"}
                onClick={() => {
                  if (newStaff.length > 1) {
                    const tempStaffInfo = [...newStaff];
                    const tempHasError = [...hasErrors];
                    tempHasError.pop();
                    tempStaffInfo.pop();
                    setNewStaff(tempStaffInfo);
                    setHasErrors(tempHasError);
                  }
                }}
              />
            </div>
            <div
              className="lg:grid lg:gap-6 gap-4 flex flex-col grid-cols-2 mt-6"
              key={idx}
            >
              <SnapInput
                _id={""}
                label="First Name"
                error={hasErrors[idx].firstName}
                onSnap-input-change={(e) => {
                  handleNewStaff(e.detail.target.value, "First", staff, idx);
                  hasErrors[idx].firstName = false;
                  setHasErrors([...hasErrors]);
                }}
              />
              <SnapInput
                _id={""}
                label="Last Name"
                error={hasErrors[idx].lastName}
                onSnap-input-change={(e) => {
                  handleNewStaff(e.detail.target.value, "Last", staff, idx);
                  hasErrors[idx].lastName = false;
                  setHasErrors([...hasErrors]);
                }}
              />
              <SnapInput
                _id={""}
                label="Email"
                error={hasErrors[idx].email || hasErrors[idx].sameEmail}
                onSnap-input-change={(e) => {
                  handleNewStaff(e.detail.target.value, "Email", staff, idx);
                  hasErrors[idx].email = false;
                  hasErrors[idx].sameEmail = false;
                  setHasErrors([...hasErrors]);
                }}
              />
              <SnapSelectMenu
                label="Role"
                className="-mt-1"
                error={hasErrors[idx].role}
                selectMenuOptions={[
                  {
                    name: "Group Staff",
                    selected: staff.type === "group_staff",
                  },
                  {
                    name: "Group Observer",
                    selected: staff.type === "group_observer",
                  },
                ]}
                placeholder="- Choose a Role -"
                onSnap-select-menu-updated={(e) => {
                  let selected = e.detail.find((r) => r.selected)?.name ?? "";
                  handleNewStaff(selected, "Role", staff, idx);
                  hasErrors[idx].role = false;
                }}
              />
            </div>
            <Divider />
          </div>
        );
      })}
      <div
        className="flex text-blue-600 text-base font-bold cursor-pointer justify-end mt-4"
        onClick={() => {
          setNewStaff([
            ...newStaff,
            {
              id: "",
              firstName: "",
              lastName: "",
              email: "",
              type: "",
              status: "Not Signed Up",
              debitCard: "-",
            },
          ]);
          setHasErrors([
            ...hasErrors,
            {
              firstName: false,
              lastName: false,
              email: false,
              sameEmail: false,
              role: false,
            },
          ]);
        }}
      >
        <SnapIcon icon="plus-solid" size="sm" />
        <p className="self-center">Add New Staff</p>
      </div>
    </div>
  );
}

export default AddStaff;
