import approvalPending from "assets/Pending_Account.svg";
import { SnapButton, SnapLink } from "suit";

function Pending() {
  return (
    <div className="flex flex-col items-center lg:mx-72 mx-10">
      <p className="text-lg font-semibold">Approval Pending</p>
      <img
        src={approvalPending}
        alt="for document under review"
        className="mt-4"
      />
      <p className="text-sm text-center text-gray-500 mt-4">
        You have limited access to Spend while your application under review.
        Once permissions are determined, notification will be sent your email.
        In the meantime, you can{" "}
        <SnapLink
          text="Learn More"
          href="https://helpdesk.snapraise.com/snap-spend"
          target="_blank"
          className="text-blue-600 font-bold"
        />{" "}
        about Snap! Spend.
      </p>
      <SnapButton variant="primary" className="mt-5">
        Explore Spend
      </SnapButton>
    </div>
  );
}

export default Pending;
