import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import {
  DateRangeEnum,
  ExternalTransferDirectionEnum,
  SpendBankTransaction,
  useSpendTransactionsCompletedWheredQuery,
  useSpendTransactionsPendingWheredQuery,
} from "graphql/generated";
import { useContextStrict } from "helpers/context-strict";
import { FormatMoney } from "helpers/format-money";
import { getBadgeColor } from "helpers/status-color";
import { getTransactionType, getTransactionTitle } from "helpers/transaction";
import useModal from "hooks/use-modal";
import {
  transactionDateRangeFilters,
  transactionsFilters,
} from "pages/programs/banking/metadata/filter-dropdown-options/transaction-status";
import { transactionTypeFilters } from "pages/programs/banking/metadata/filter-dropdown-options/transaction-type";
import { useEffect, useState } from "react";
import DataCard from "shared-components/data-card";
import Divider from "shared-components/divider";
import Filter from "shared-components/filter";
import AddBudgetItem from "shared-components/modal/reconciliation/add-budget-item";
import ApplyToPlayerInvoice from "shared-components/modal/reconciliation/apply-to-player-invoice";
import UndoApplyToPlayerInvoice from "shared-components/modal/reconciliation/undo-apply-to-player-invoice";
import ReconcileAs from "shared-components/modal/reconciliation/reconcile-as";
import TransactionDetails from "shared-components/modal/transaction-details.tsx";
import { SearchInput } from "shared-components/search-input";
import ShowingResults from "shared-components/showing-results";
import { VerticalValueStyle } from "shared-components/vertical-label-value";
import {
  SnapActionSheet,
  SnapBadge,
  SnapCheckboxButton,
  SnapDropDown,
  SnapIcon,
  SnapPagination,
} from "suit";
import { LabelValueObject } from "types/label-value-object";
import { ITEMS_PER_PAGE } from "../../../constants";
import RequestCancel from "shared-components/fixed-modal/request-cancel";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import { ApplyingBankTransaction } from "types/team-banking";
import DisputeTransaction from "pages/programs/banking/modals/dispute-transaction";
import SplitIOContext from "context/splitio-context";
import { SpinnerContainer } from "shared-components/spinner";
type TransactionsProps = {
  isFilter: boolean;
  menuItems: any[];
  canUpdateBudgetItem: boolean;
  isArchived: boolean;
};

function Transactions({
  isFilter,
  menuItems,
  canUpdateBudgetItem,
  isArchived,
}: TransactionsProps) {
  const splits = useContextStrict(SplitIOContext);
  const [
    spe1754FilterTransactionsDateRange,
    setSPE1754FilterTransactionsDateRange,
  ] = useState(false);
  useEffect(() => {
    setSPE1754FilterTransactionsDateRange(
      splits.isTreatmentOn(splits.act.spe1754)
    );
  }, [splits]);

  const activeGroup = useContextStrict(GroupContext);
  const [focusOnTransaction, setFocusOnTransaction] = useState<
    ApplyingBankTransaction | undefined
  >(undefined);
  const {
    isOpen: transactionDetailsOpen,
    toggle: transactionDetailsToggle,
    tabSelectedValue,
    setSelectedTab,
  } = useModal();
  const { isOpen: filterActionsOpen, toggle: filterActionsToggle } = useModal();
  const { isOpen: applyToPlayerOpen, toggle: applyToPlayerToggle } = useModal();
  const { isOpen: unApplyToPlayerOpen, toggle: unApplyToPlayerToggle } =
    useModal();
  const { isOpen: reconcileIncomeOpen, toggle: reconcileIncomeToggle } =
    useModal();
  const { isOpen: reconcileExpenseOpen, toggle: reconcileExpenseToggle } =
    useModal();
  const { isOpen: incomeBudgetOpen, toggle: incomeBudgetToggle } = useModal();
  const { isOpen: expenseBudgetOpen, toggle: expenseBudgetToggle } = useModal();
  const { isOpen: requestCancelOpen, toggle: requestCancelToggle } = useModal();
  const { isOpen: disputeTransactionOpen, toggle: disputeTransactionToggle } =
    useModal();
  const [selectedTran, setSelectedTran] = useState<
    ApplyingBankTransaction | any | undefined
  >(undefined);

  const [selectedModal, setSelectedModal] = useState(0);
  const [completedPage, setCompletedPage] = useState(0);

  const [totalCompleted, setTotalCompleted] = useState(0);
  const [completedTransactions, setCompletedTransactions] = useState<
    ApplyingBankTransaction[]
  >([]);
  const [pendingTransactions, setPendingTransactions] = useState<
    SpendBankTransaction[]
  >([]);

  const totalTransactions = pendingTransactions.length + totalCompleted;
  const [searchText, setSearchText] = useState("");

  const [filters, setFilters] =
    useState<DropdownMenuItem[]>(transactionsFilters);
  const [dateRangeFilters] = useState<DropdownMenuItem[]>(
    transactionDateRangeFilters
  );
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeEnum>(
    DateRangeEnum.ThirtyDays
  );
  const [transactionFilters, setTransactionFilters] = useState<
    DropdownMenuItem[]
  >(transactionTypeFilters);
  const [hidePending, setHidePending] = useState(false);
  const [whereFilters, setWhereFilters] = useState<{
    type: string[] | null;
    reconciled: boolean | null;
    hasAttachment: boolean | null;
    nameIncludes: string | null;
    status: string[];
    direction: ExternalTransferDirectionEnum | null;
  }>({
    type: null,
    reconciled: null,
    hasAttachment: null,
    nameIncludes: null,
    status: ["Settled", "Canceled", "Returned"],
    direction: null,
  });

  const {
    loading: loadingCompleted,
    data: completedData,
    refetch: refetchCompleted,
  } = useSpendTransactionsCompletedWheredQuery({
    variables: {
      pagination: {
        limit: ITEMS_PER_PAGE,
        offset: completedPage * ITEMS_PER_PAGE,
      },
      where: {
        ...whereFilters,
        groupIdOrgId: activeGroup.activeGroup?.id ?? "",
      },
      disableCache: true,
      dateRange: selectedDateRange,
    },
    skip: !activeGroup.activeGroup?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      !loadingCompleted &&
      completedData &&
      completedData.spendTransactionsFiltered
    ) {
      setTotalCompleted(completedData.spendTransactionsFiltered?.count || 0);
      const transactions =
        (completedData.spendTransactionsFiltered?.transactions?.map((trx) => {
          const appliedAmount = trx?.reconciliation?.reconciledTo
            ? MapAndCalcSum(trx.reconciliation.reconciledTo, "amount")
            : 0;
          const applicableAmount = Math.round(
            (trx?.amount ?? 0) - appliedAmount
          );
          return { ...trx, applicableAmount };
        }) || []) as ApplyingBankTransaction[];
      setCompletedTransactions(transactions);
    }
  }, [loadingCompleted, completedData]);

  const {
    loading: loadingPending,
    data: pendingData,
    refetch: refetchPending,
  } = useSpendTransactionsPendingWheredQuery({
    variables: {
      where: {
        ...whereFilters,
        groupIdOrgId: activeGroup.activeGroup?.id ?? "",
        status: ["Pending"],
      },
      disableCache: true,
    },
    skip: !activeGroup.activeGroup?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      !loadingPending &&
      pendingData &&
      pendingData.spendTransactionsFiltered
    ) {
      const correlationIds: string[] = [];
      const transactions: SpendBankTransaction[] = [];
      pendingData.spendTransactionsFiltered.transactions?.forEach(
        (transaction) => {
          const correlationId = transaction?.correlationId;
          if (correlationId && !correlationIds.includes(correlationId)) {
            correlationIds.push(correlationId);
            transactions.push(transaction as SpendBankTransaction);
          }
        }
      );
      setPendingTransactions(transactions);
    }
  }, [loadingPending, pendingData]);

  window.addEventListener("unitPaymentCreated", (e) => {
    refetchCompleted();
    refetchPending();
  });

  const setQueryFilterType = (type: string, items: any[]) => {
    let filterType = whereFilters?.type;
    let reconciled = whereFilters?.reconciled;
    let hasAttachment = whereFilters?.hasAttachment;
    let nameIncludes = whereFilters?.nameIncludes;
    let status = whereFilters?.status;
    let direction = whereFilters?.direction;

    if (type === "Types") {
      let tempFilters = transactionFilters.map((filter) => {
        let item = { ...filter };
        if (item.name === items[0].name) {
          item.selected = true;
        } else {
          item.selected = false;
        }
        return item;
      });
      setTransactionFilters(tempFilters);
      if (items[0].value === "") {
        filterType = null;
      } else {
        filterType = items[0].value;
      }
    } else if (type === "Filters") {
      if (items[0].selected) {
        reconciled = false;
      } else {
        reconciled = null;
      }

      if (items[1].selected) {
        direction = ExternalTransferDirectionEnum.Debit;
        hasAttachment = true;
      } else {
        direction = null;
        hasAttachment = null;
      }

      if (items[2].selected) {
        status = ["Settled"];
      } else {
        status = ["Settled", "Canceled", "Returned"];
      }

      setHidePending(items[3].selected);
    } else {
      if (items[0].value === "") {
        nameIncludes = null;
      } else {
        nameIncludes = items[0].value;
      }
    }

    setWhereFilters({
      type: filterType ?? null,
      reconciled: reconciled ?? null,
      hasAttachment: hasAttachment ?? null,
      nameIncludes: nameIncludes ?? null,
      status: status ?? [],
      direction: direction ?? null,
    });
  };

  useEffect(() => {
    setQueryFilterType("search", [{ name: searchText, value: searchText }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const prepLeftData = (transaction: SpendBankTransaction) => {
    let leftData: LabelValueObject[] = [];
    leftData.push({
      key: "Type",
      value: getTransactionType(transaction),
      className: "capitalize",
    });
    if (transaction.transactionNote) {
      leftData.push({
        key: "Note",
        value: transaction.transactionNote,
      });
    }
    return leftData;
  };
  const prepRightData = (transaction: SpendBankTransaction) => {
    let rightData: LabelValueObject[] = [];

    let date = transaction.effective?.split("T").at(0);
    let year = date?.split("-").at(0);
    let month = date?.split("-").at(1);
    let day = date?.split("-").at(2);

    rightData.push({
      key: "Date",
      value: `${month}/${day}/${year}`,
    });

    const factor = transaction.direction?.toLowerCase() === "credit" ? 1 : -1;
    const transactionValue =
      ((transaction.amount || 0) - (transaction.snapAmount || 0)) * factor;
    let amountColor = transactionValue <= 0 ? "text-red-600" : "text-gray-800";
    const isReturned = transaction.transactionStatus === "Returned";
    rightData.push({
      key: "Amount",
      value: FormatMoney(transactionValue),
      valueStyle: isReturned
        ? VerticalValueStyle.ReturnText
        : VerticalValueStyle.ColorText,
      valueColor: isReturned ? "text-gray-600" : amountColor,
    });

    if (transaction.metadata?.balance) {
      rightData.push({
        key: "Balance",
        value: FormatMoney(Number(transaction.metadata?.balance) || 0), // TODO - Update Balance
        customContainerStyle: !menuItems.length ? "lg:border-r-0" : "",
      });
    }

    return rightData;
  };

  const menuItemFilter = (transaction: SpendBankTransaction): any[] => {
    const paymentType = getTransactionType(transaction);
    let reconciledAmount = Math.abs(transaction.totalReconciled ?? 0);
    const amount = (transaction.amount ?? 0) - (transaction.snapAmount ?? 0);
    const isFullReconciled =
      transaction.totalReconciled != null && amount <= reconciledAmount;
    const reconciled = transaction.isReconciled
      ? isFullReconciled
        ? "Reconciled"
        : "Partially Reconciled"
      : "Unreconciled";

    let reconType: string | undefined;
    if (transaction.typeReconciled === "budget_income") {
      reconType = "Reconciled Budget Income";
    } else if (transaction.typeReconciled === "budget_expense") {
      reconType = "Reconciled Budget Expense";
    } else if (transaction.typeReconciled === "invoice") {
      reconType =
        paymentType === "Payment Invoice"
          ? "Auto Reconciled Invoice"
          : "Invoice";
    }
    reconType = reconType?.trim();
    const filteredMenuItems = menuItems.filter((menuItem) => {
      if (transaction.transactionStatus === "Returned") {
        return false;
      }
      if (transaction.status?.toLowerCase() === "pending") {
        // menu items that are pending relavent
        const cancelTypes = ["checkpayment", "ach"];
        return (
          cancelTypes.includes(
            transaction.transactionType?.toLowerCase() ?? ""
          ) &&
          menuItem.type.includes(
            transaction.transactionType?.toLowerCase() ?? ""
          ) &&
          menuItem.isPending
        );
      }
      if (!transaction.isReconciled) {
        // menu items that are not reconciled
        return (
          menuItem.type.includes(paymentType.toLowerCase()) &&
          menuItem.key.includes(reconciled) &&
          !menuItem.isPending
        );
      } else {
        return (
          menuItem.type.includes(paymentType.toLowerCase()) &&
          menuItem.key.includes(reconciled) &&
          reconType === menuItem.secondaryKey
        );
      }
    });
    return filteredMenuItems;
  };
  const modalTogglerFromMenuTitle = (menuTitle: any): void => {
    switch (menuTitle) {
      case "Apply to Participant Invoice":
        applyToPlayerToggle();
        break;
      case "Undo Apply to Participant Invoice":
        unApplyToPlayerToggle();
        break;
      case "Reconcile as Income":
        reconcileIncomeToggle();
        break;
      case "Reconcile as Expense":
        reconcileExpenseToggle();
        break;
      case "Add Income Budget Item":
        incomeBudgetToggle();
        break;
      case "Add Expense Budget Item":
        expenseBudgetToggle();
        break;
      case "View/Edit Reconciled Income":
        reconcileIncomeToggle();
        break;
      case "View/Edit Reconciled Expense":
        reconcileExpenseToggle();
        break;
      case "Request Cancel":
        requestCancelToggle();
        break;
      case "Dispute Transaction":
        disputeTransactionToggle();
        break;
    }
  };
  const prepTitleExtra = (transaction: SpendBankTransaction) => {
    const reconciledAmount = Math.abs(transaction.totalReconciled ?? 0);
    const amount = (transaction.amount ?? 0) - (transaction.snapAmount ?? 0);
    const partiallyReconciled = amount > reconciledAmount;
    const isReturned = transaction.transactionStatus === "Returned";
    return (
      <div className="flex">
        {isReturned && (
          <SnapBadge color={getBadgeColor("Returned")} class="ml-5">
            Returned
          </SnapBadge>
        )}
        {!transaction.isReconciled && !isReturned && (
          <SnapBadge color={getBadgeColor("Unreconciled")} class="ml-5">
            Unreconciled
          </SnapBadge>
        )}
        {transaction.isReconciled && partiallyReconciled && (
          <SnapBadge color={getBadgeColor("partially_reconciled")} class="ml-5">
            Partially Reconciled
          </SnapBadge>
        )}
        {transaction.hasAttachments && (
          <SnapIcon icon="paper-clip-solid" color="#2563EB" />
        )}
      </div>
    );
  };

  return (
    <div className={isFilter ? "mt-4" : "-mt-2"}>
      {isFilter && (
        <>
          <div className={"mb-4"}>
            <SearchInput setSearchValue={setSearchText} />
          </div>
          <div className="lg:flex ml-2 lg:ml-0">
            <Filter
              dropdownOptions={transactionFilters}
              handleSelect={(e) => setQueryFilterType("Types", [e.detail])}
            />
            <div className="hidden lg:flex lg:ml-6">
              <p className="text-xs font-medium self-center text-gray-500 mr-2">
                Filter:
              </p>
              <SnapDropDown
                buttonText="Select Filters"
                options={filters}
                multi
                leftHang
                onSnap-dropdown-updated={(e) => {
                  setQueryFilterType("Filters", e.detail as any[]);
                  setFilters(e.detail as DropdownMenuItem[]);
                }}
              />
            </div>
            {spe1754FilterTransactionsDateRange && (
              <div className="flex lg:ml-6 lg:mt-0 mt-4">
                <p className="text-xs font-medium self-center text-gray-500 mr-2">
                  Date Range:
                </p>
                <SnapDropDown
                  buttonText="Select Filters"
                  options={dateRangeFilters}
                  leftHang
                  onSnap-dropdown-item-selected={(e) => {
                    setSelectedDateRange(e.detail.value as DateRangeEnum);
                  }}
                />
              </div>
            )}

            <div className="lg:hidden flex mt-10" onClick={filterActionsToggle}>
              <SnapIcon icon="filter-solid" size="sm" color="#3B82F6" />
              <p className="text-base font-bold text-blue-600">Filters</p>
            </div>
            {filterActionsOpen && (
              <SnapActionSheet header="Filter" onClick={filterActionsToggle}>
                <Divider isVisibleOnMobile className="mt-0" />
                {transactionsFilters.map((filter, idx) => {
                  return (
                    <div key={filter.name + idx} className="mx-4 mt-6">
                      <SnapCheckboxButton
                        checked={filter.selected}
                        label={filter.name}
                        onClick={() => {
                          let selectedFilter = {
                            ...filter,
                            selected: !filter.selected,
                          };
                          let tempFilters = [...filters];
                          tempFilters.splice(idx, 1, selectedFilter);
                          setQueryFilterType("Filters", tempFilters);
                          setFilters(tempFilters);
                        }}
                      />
                    </div>
                  );
                })}
              </SnapActionSheet>
            )}
          </div>
          <Divider isVisibleOnMobile />
        </>
      )}
      <ShowingResults
        totalNumOfResults={totalTransactions}
        numOfResultsBeingDisplayed={Math.min(
          totalTransactions,
          ITEMS_PER_PAGE + pendingTransactions.length
        )}
        startingNumOfResults={totalTransactions === 0 ? 0 : 1}
      />
      {!hidePending && pendingTransactions.length > 0 && (
        <>
          <p className="text-sm font-medium text-gray-500">
            Pending
            <span className="ml-2 inline-block">
              {pendingTransactions.length}
            </span>
          </p>
          <Divider className="mb-4" />
          <div className={"relative"}>
            {pendingTransactions.map((transaction, idx) => {
              return (
                <DataCard
                  title={getTransactionTitle(transaction)}
                  key={transaction.id || idx}
                  titleExtra={prepTitleExtra(transaction)}
                  titleAction={() => {
                    setSelectedTran(transaction);
                    transactionDetailsToggle();
                  }}
                  kvLeft={prepLeftData(transaction)}
                  kvRight={prepRightData(transaction)}
                  action={
                    canUpdateBudgetItem && !activeGroup.isArchived ? 1 : 0
                  }
                  menuItems={menuItemFilter(transaction)}
                  menuClickListener={(title) => {
                    setFocusOnTransaction(transaction);
                    modalTogglerFromMenuTitle(title);
                  }}
                />
              );
            })}
            <SpinnerContainer loading={loadingPending} />
          </div>
        </>
      )}

      <br className={"mt-4 block"} />
      <p className="text-sm font-medium text-gray-500">
        Complete
        <span className="ml-2 inline-block">{totalCompleted}</span>
      </p>
      <Divider className="mb-4" />
      <div className={"relative mb-4"}>
        {completedTransactions.map((transaction, idx) => {
          return (
            <DataCard
              title={getTransactionTitle(transaction) ?? "Transaction"}
              key={transaction.id || idx}
              titleExtra={prepTitleExtra(transaction)}
              titleAction={() => {
                setSelectedTran(transaction);
                transactionDetailsToggle();
              }}
              kvLeft={prepLeftData(transaction)}
              kvRight={prepRightData(transaction)}
              action={canUpdateBudgetItem && !activeGroup.isArchived ? 1 : 0}
              menuItems={menuItemFilter(transaction)}
              menuClickListener={(title) => {
                setFocusOnTransaction(transaction);
                modalTogglerFromMenuTitle(title);
              }}
            />
          );
        })}
        <SpinnerContainer loading={loadingCompleted} />
      </div>
      <SnapPagination
        itemCount={totalCompleted}
        currentPage={completedPage}
        pageSize={ITEMS_PER_PAGE}
        onSnap-pagination-page-changed={(e) => {
          setCompletedPage(e.detail);
        }}
      />
      {applyToPlayerOpen && focusOnTransaction && (
        <ApplyToPlayerInvoice
          applyToPlayerOpen={applyToPlayerOpen}
          applyToPlayerToggle={applyToPlayerToggle}
          transaction={focusOnTransaction}
          startAt={1}
          team={activeGroup?.activeGroup}
        />
      )}
      {unApplyToPlayerOpen && focusOnTransaction && (
        <UndoApplyToPlayerInvoice
          undoApplyToPlayerOpen={unApplyToPlayerOpen}
          undoApplyToPlayerToggle={unApplyToPlayerToggle}
          transaction={focusOnTransaction}
        />
      )}
      {focusOnTransaction && (reconcileIncomeOpen || reconcileExpenseOpen) && (
        <ReconcileAs
          type="Group"
          transaction={focusOnTransaction}
          directionType={reconcileIncomeOpen ? "Income" : "Expense"}
          reconcileOpen={reconcileIncomeOpen || reconcileExpenseOpen}
          reconcileToggle={() => {
            if (reconcileIncomeOpen) reconcileIncomeToggle();
            else reconcileExpenseToggle();
          }}
        />
      )}
      {focusOnTransaction && (incomeBudgetOpen || expenseBudgetOpen) && (
        <AddBudgetItem
          type="Group"
          directionType={incomeBudgetOpen ? "Income" : "Expense"}
          budgetItemOpen={incomeBudgetOpen || expenseBudgetOpen}
          budgetItemToggle={() => {
            if (incomeBudgetOpen) incomeBudgetToggle();
            else expenseBudgetToggle();
          }}
          transaction={focusOnTransaction}
        />
      )}
      {focusOnTransaction && requestCancelOpen && (
        <RequestCancel
          requestCancelOpen={requestCancelOpen}
          requestCancelToggle={requestCancelToggle}
          focusOnTransaction={focusOnTransaction}
          type="group"
        />
      )}

      {focusOnTransaction && disputeTransactionOpen && (
        <DisputeTransaction
          disputeTransactionOpen={disputeTransactionOpen}
          disputeTransactionToggle={disputeTransactionToggle}
        />
      )}

      {selectedTran && transactionDetailsOpen && (
        <TransactionDetails
          isOpen={transactionDetailsOpen}
          toggle={transactionDetailsToggle}
          tabSelectedValue={tabSelectedValue}
          selectedTran={selectedTran}
          selectedModal={selectedModal}
          setSelectedModal={setSelectedModal}
          setSelectedTab={setSelectedTab}
          canEditNotesAndAttachments={canUpdateBudgetItem}
          perspectiveId={activeGroup?.activeGroup?.id ?? undefined}
        />
      )}
    </div>
  );
}

export default Transactions;
