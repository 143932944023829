import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import { SpendGroup, SpendOrganization } from "graphql/generated";
import { useContext, useState } from "react";
import { useScreenSize } from "./use-screen-size";

export default function useDisplay() {
  const Group = useContext(GroupContext);

  const [selectedDropdownOption, setSelectedDropdownOption] = useState<
    DropdownMenuItem | undefined
  >();
  const [dropdownMenuOptions, setDropdownMenuOptions] = useState<
    DropdownMenuItem[]
  >([]);
  const [settingsSaved, setSettingsSaved] = useState(true);
  const [makePaymentDataModalOpen, setMakePaymentDataModalOpen] = useState<{
    isOpen: boolean;
    groupRosterId?: string;
  }>({ isOpen: false });
  const [isDepositCheckOpen, setIsDepositCheckOpen] = useState(false);
  const [unitAccount, setUnitAccount] = useState("");
  const [sendTo, setsendTo] = useState("");
  const [proGroupId, setProGroupId] = useState("");
  const [programName, setProgramName] = useState("");

  const setSelectedUnitAccount = (
    account: string,
    sendToName: string,
    id: string,
    programName: string
  ) => {
    setUnitAccount(account);
    setsendTo(sendToName);
    setIsDepositCheckOpen(true);
    setProGroupId(id);
    setProgramName(programName);
  };

  const setDropdownOptions = (
    program: SpendOrganization | undefined,
    groups: SpendGroup[] | undefined
  ) => {
    let programOptions = {
      name: program?.nickname || program?.legalName || "",
      text: program?.nickname || program?.legalName || "",
      value: program?.id || "",
      section: "Program",
      selected: false,
    };
    let groupOptions = groups?.map((group) => {
      return {
        name: group?.name ?? "",
        text: group?.name ?? "",
        value: `${group?.id}|${group.latestSeason?.id}`,
        section: "Group",
        selected: false,
      };
    });
    let options: DropdownMenuItem[] = [];
    if (programOptions.name !== "") {
      programOptions.selected = true;
      options.push(programOptions);
      if (groupOptions) {
        options.push(...(groupOptions ?? []));
      }
    } else if (groupOptions && groupOptions.length > 0) {
      groupOptions[0].selected = true;
      options.push(...(groupOptions ?? []));
      Group?.setAndStoreActiveGroup(groups?.at(0));
    }
    setDropdownMenuOptions(options);
    setSelectedDropdownOption(options.at(0));
  };

  //TODO - MOVE SPINNER HERE

  return {
    selectedDropdownOption,
    setSelectedDropdownOption,
    settingsSaved,
    setSettingsSaved,
    makePaymentDataModalOpen,
    setMakePaymentDataModalOpen,
    isDepositCheckOpen,
    setIsDepositCheckOpen,
    unitAccount,
    setSelectedUnitAccount,
    sendTo,
    programName,
    ...useScreenSize(),
    proGroupId,
    dropdownMenuOptions,
    setDropdownMenuOptions,
    setDropdownOptions,
  };
}
