import { useMutation } from "@apollo/client";
import GroupContext from "context/group-context";
import SeasonContext from "context/season-context";
import ToastContext from "context/toast-context";
import UserContext from "context/user-context";
import ProgramContext from "context/program-context";
import {
  useSpendGroupsFilteredLazyQuery,
  SignupAgreement,
  Maybe,
} from "graphql/generated";
import { UPDATE_GROUP_AND_SEASON_SETTINGS_FORM } from "graphql/mutations/group";
import { useContextStrict } from "helpers/context-strict";
import { emptyStringCheck } from "helpers/empty-string-check";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import FloatingActionBtn from "shared-components/floating-action-btn";
import ArchiveGroup from "shared-components/modal/groups/archive-group";
import CopyGroup from "shared-components/modal/groups/copy-group";
import SignUpLink from "shared-components/sign-up-link";
import Spinner from "shared-components/spinner";
import {
  SnapActionSheet,
  SnapButton,
  SnapInput,
  SnapLabeledToggle,
  SnapLink,
  SnapCheckboxButton,
} from "suit";
import {
  DiscountErrorType,
  GroupErrorType,
  SeasonErrorType,
  defaultDiscountError,
  defaultGroupError,
  defaultSeasonError,
} from "types/errors";
import { SpendPermissions } from "types/roles-permissions";
import { GroupSettings } from "types/settings";
import DiscountSettings, {
  Validate as ValidateDiscountSettings,
} from "./discount-settings";
import AddNewSeason from "./modals/add-new-season";
import DatePicker from "shared-components/date-picker";
import { isNullOrEmpty } from "helpers/null-or-empty";
import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";

function Settings() {
  const User = useContext(UserContext);
  const Group = useContextStrict(GroupContext);
  const Season = useContext(SeasonContext);
  const Toast = useContext(ToastContext);
  const Program = useContext(ProgramContext);
  const { isOpen: copyGroupOpen, toggle: copyGroupToggle } = useModal();
  const { isOpen: archiveGroupOpen, toggle: archiveGroupToggle } = useModal();
  const { isOpen: addSeasonOpen, toggle: addSeasonToggle } = useModal();

  const canEditSettings =
    useContext(UserContext)?.checkSpendPermission(
      SpendPermissions.groupSettingsUpdate
    ) ?? false;

  const [groupName, setGroupName] = useState("");
  const [seasonStartDate, setSeasonStartDate] = useState("");
  const [seasonEndDate, setSeasonEndDate] = useState("");
  const [seasonNickname, setSeasonNickname] = useState("");
  const [discountsEnabled, setDiscountEnabled] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountCutOffDate, setDiscountCutOffDate] = useState("");
  const [discountMinimumPurchase, setDiscountMinimumPurchase] = useState(0);
  const [signUpToggle, setSignUpToggle] = useState(false);
  const [isSettingOptionsOpen, setIsSettingOptionsOpen] = useState(false);
  const [hasError, setHasError] = useState<GroupErrorType>(defaultGroupError);
  const [hasDiscountError, setHasDiscountError] =
    useState<DiscountErrorType>(defaultDiscountError);
  const [hasSeasonErrors, setHasSeasonErrors] =
    useState<SeasonErrorType>(defaultSeasonError);
  const [isBtnActive, setIsBtnActive] = useState(true);
  const [signupAgreementRequired, setSignupAgreementRequired] = useState(false);
  const [signupAgreement, setSignupAgreement] = useState<
    Maybe<SignupAgreement> | undefined
  >(undefined);

  useEffect(() => {
    setGroupName(Group.activeGroup?.name ?? "");
    setSeasonStartDate(Season?.selectedSeason?.startDateAt ?? "");
    setSeasonEndDate(Season?.selectedSeason?.endDateAt ?? "");
    setSeasonNickname(Season?.selectedSeason?.name ?? "");
    setDiscountEnabled(Group.activeGroup?.enableDiscount ?? false);
    setDiscountAmount(Group.activeGroup?.discountAmount ?? 0);
    setDiscountCutOffDate(Group.activeGroup?.discountCutOffDate ?? "");
    setDiscountMinimumPurchase(Group.activeGroup?.minimumDiscountPurchase ?? 0);
    setSignUpToggle(Season?.selectedSeason?.isLinkEnabled ?? false);
    setSignupAgreementRequired(Group.activeGroup?.isRequireAgreement ?? false);
    setSignupAgreement(Program?.settings?.signUpAgreement);
  }, [Group.activeGroup, Season?.selectedSeason, Program?.settings]);

  const [
    updateGroupAndSeasonSettingsForm,
    { data: updateData, loading: updateLoading },
  ] = useMutation(UPDATE_GROUP_AND_SEASON_SETTINGS_FORM, {
    refetchQueries: [
      "SpendGroupsFiltered",
      "SpendOrganizationGroupsCategories",
    ],
  });

  const [getGroup, { data: updatedGroupData, loading: loadingUpdatedGroup }] =
    useSpendGroupsFilteredLazyQuery();

  useEffect(() => {
    if (!updateLoading && updateData) {
      setHasError(defaultGroupError);
      setHasSeasonErrors(defaultSeasonError);
      setHasDiscountError(defaultDiscountError);
      getGroup({
        variables: {
          where: {
            ids: [Group.activeGroup?.id ?? ""],
          },
        },
      });
    }
    // eslint-disable-next-line
  }, [updateLoading, updateData]);

  useEffect(() => {
    if (!loadingUpdatedGroup && updatedGroupData) {
      setIsBtnActive(true);
      const group = updatedGroupData.spendGroupsFiltered?.groups?.at(0);
      Group.setAndStoreActiveGroup(group!);
      Season?.handleSelectedSeason({ seasonsToSet: group?.seasons });
      Toast?.setToast({
        message: "Settings Successfully Updated",
        type: "success",
      });
    }
    // eslint-disable-next-line
  }, [loadingUpdatedGroup, updatedGroupData]);

  const handleSave = () => {
    const groupErrors = GroupValidate(groupName);
    setHasError(groupErrors);
    const seasonErrors = SeasonValidate({ seasonStartDate, seasonEndDate });
    setHasSeasonErrors(seasonErrors);
    const discountErrors = ValidateDiscountSettings({
      discountsEnabled,
      discountAmount,
      discountCutOffDate,
      discountMinimumPurchase,
    });
    setHasDiscountError(discountErrors);
    const errorMessages = [
      ...groupErrors.messages,
      ...seasonErrors.messages,
      ...discountErrors.messages,
    ];
    if (errorMessages.length) {
      Toast?.setToast({
        message: errorMessages[0],
        type: "danger",
      });
    } else {
      setIsBtnActive(false);
      updateGroupAndSeasonSettingsForm({
        variables: {
          spendGroupUpdateId: Group.activeGroup?.id,
          spendGroupInput: {
            name: groupName,
            isLinkEnabled: signUpToggle,
            isRequireAgreement: signupAgreementRequired,
            enableDiscount: discountsEnabled,
            discountAmount: discountsEnabled ? discountAmount : null,
            discountCutOffDate: discountsEnabled ? discountCutOffDate : null,
            minimumDiscountPurchase: discountsEnabled
              ? discountMinimumPurchase
              : null,
            seasonStartAt: seasonStartDate,
            seasonEndAt: seasonEndDate,
            shareAccount: Group.activeGroup?.sharedAccount,
          },
          spendSeasonUpdateId: Season?.selectedSeason?.id,
          spendSeasonInput: {
            startDateAt: seasonStartDate,
            endDateAt: seasonEndDate,
            groupId: Group.activeGroup?.id,
            name: seasonNickname,
            isLinkEnabled: signUpToggle,
          },
        },
      });
    }
  };

  return (
    <div className="wrapper">
      <div className="card">
        <div className="flex">
          <p className="self-center text-lg font-semibold mr-auto">Settings</p>
          <div className="lg:flex hidden">
            <SnapButton
              hidden={
                User?._session?.role?.name === "group_staff" ||
                !canEditSettings ||
                Group?.isArchived
              }
              className="mr-2"
              variant="tertiary"
              icon="duplicate-solid"
              onClick={copyGroupToggle}
            >
              Copy Group
            </SnapButton>
            <SnapButton
              hidden={
                User?._session?.role?.name === "group_staff" ||
                !canEditSettings ||
                Group?.isArchived
              }
              className="mr-2"
              variant="secondary"
              icon="archive-solid"
              onClick={archiveGroupToggle}
            >
              Archive Group
            </SnapButton>
            <SnapButton
              hidden={!canEditSettings || Group?.isArchived}
              variant="primary"
              icon="plus-solid"
              onClick={() => {
                addSeasonToggle();
              }}
            >
              Add New Season
            </SnapButton>
          </div>
        </div>
        <div className="mt-5 border border-gray-200 p-4 rounded-lg">
          <p className="text-base font-medium text-gray-600 lg:mb-2 mb-4">
            Group Settings
          </p>
          <div className="lg:w-[50%]">
            <SnapInput
              _id={"group_name_input"}
              label="Group Name"
              disabled={!canEditSettings || Group?.isArchived}
              value={groupName}
              onBlur={(e) => {
                setGroupName(e.target.value);
                setHasError(defaultGroupError);
              }}
              error={hasError.groupName}
            />
          </div>
        </div>
        <div className="mt-5 border border-gray-200 p-4 rounded-lg">
          <p className="text-base font-medium text-gray-600 lg:mb-2 mb-4">
            Season Setting
          </p>
          <div className="lg:flex mt-2">
            <>
              <DatePicker
                className={"lg:mr-5 lg:w-[30%]"}
                label={"Season Start Date"}
                date={getDatePickerValue(seasonStartDate ?? "")}
                setDate={(e) => {
                  const newDate = setDatePickerValue(e);
                  setSeasonStartDate(newDate);
                  setHasSeasonErrors({
                    ...hasSeasonErrors,
                    seasonStart: false,
                  });
                }}
                hasError={
                  hasSeasonErrors.seasonStart || hasSeasonErrors.seasonDate
                }
              />
              <DatePicker
                className="lg:w-[30%] lg:mt-0 mt-5"
                label={"Season End Date"}
                date={getDatePickerValue(seasonEndDate ?? "")}
                setDate={(e) => {
                  const newDate = setDatePickerValue(e);
                  setSeasonEndDate(newDate);
                  setHasSeasonErrors({
                    ...hasSeasonErrors,
                    seasonEnd: false,
                  });
                }}
                hasError={
                  hasSeasonErrors.seasonEnd || hasSeasonErrors.seasonDate
                }
              />
            </>
          </div>
          <div className="lg:w-[60%] my-6">
            <SnapInput
              label="Season Nickname"
              _id="input-with-label"
              name="name"
              _type="text"
              placeholder="Enter Text Here"
              help-text="Season Nickname is optional. If there is no nickname, start and end dates will be used to identify the season."
              corner-hint="Optional"
              disabled={!canEditSettings || Group?.isArchived}
              value={seasonNickname ?? ""}
              onBlur={(e) => {
                setSeasonNickname(e.target.value);
              }}
            />
          </div>
          {signupAgreement && (
            <div className="flex my-6 pl-4 lg:pl-0">
              <SnapCheckboxButton
                onClick={(e) =>
                  setSignupAgreementRequired(!signupAgreementRequired)
                }
                checked={signupAgreementRequired}
              />
              <p className="tex-sm">
                Require "{signupAgreement.name}" for parent sign up.{" "}
              </p>
              <SnapLink
                text="Learn More"
                href="https://helpdesk.snapraise.com/support-center/adding-a-financial-commitment-agreement"
                target="_blank"
                size="sm"
                className="font-medium ml-16 pl-3 lg:ml-4 lg:pl-0"
              />
            </div>
          )}
          <SnapLabeledToggle
            id="SignUpLinkToggle"
            checked={signUpToggle}
            sr-only="srOnly"
            label="Sign Up Link"
            description=""
            toggle-side="left"
            onSnap-labeled-toggle-click={(e) => {
              if (canEditSettings && !Group.isArchived) {
                setSignUpToggle(e.detail.checked);
              }
            }}
            disabled={!canEditSettings}
          />
          {signUpToggle && <SignUpLink />}
          <hr className="my-5" />
          <SnapLabeledToggle
            checked={discountsEnabled ?? false}
            sr-only="srOnly"
            title="Enable discount for upfront payment in full"
            toggle-side="left"
            label="Enable discount for upfront payment in full"
            onSnap-labeled-toggle-click={(e) => {
              if (canEditSettings && !Group.isArchived) {
                setDiscountEnabled(e.detail.checked);
              }
            }}
            disabled={!canEditSettings || !!Group.isArchived}
          />
          <SnapLink
            text="Learn More"
            href="https://snap-mobile.zendesk.com/hc/en-us/articles/19254350455067-Giving-a-Pay-in-Full-Discount-"
            target="_blank"
            size="sm"
            className="ml-10 pl-5 mb-3"
          />
          {discountsEnabled && (
            <DiscountSettings
              canEditSettings={canEditSettings && !Group.isArchived}
              selectedGroup={{
                discountAmount,
                discountCutOffDate,
                discountMinimumPurchase,
              }}
              setDiscountAmount={setDiscountAmount}
              setDiscountCutOffDate={setDiscountCutOffDate}
              setDiscountMinimumPurchase={setDiscountMinimumPurchase}
              errors={hasDiscountError}
            />
          )}
        </div>
        <div className="lg:flex justify-end mt-4">
          <div className=" lg:w-36">
            {canEditSettings && !Group?.isArchived && (
              <SnapButton
                variant="primary"
                hidden={!canEditSettings}
                fullWidth
                onClick={handleSave}
                disabled={isBtnActive ? false : true}
              >
                {isBtnActive ? "Save" : <Spinner size="small" />}
              </SnapButton>
            )}
          </div>
        </div>
        {canEditSettings && !Group?.isArchived && (
          <FloatingActionBtn
            onClick={() => setIsSettingOptionsOpen(true)}
            icon="dots-horizontal-solid"
          />
        )}

        {isSettingOptionsOpen && (
          <SnapActionSheet
            header="Settings Actions"
            onClick={() => setIsSettingOptionsOpen(false)}
          >
            <Divider isVisibleOnMobile className="mt-0" />
            <div className="px-6">
              <SnapButton
                className="mt-4"
                variant="primary"
                icon="plus-solid"
                onClick={addSeasonToggle}
                fullWidth
              >
                Add New Season
              </SnapButton>
              <SnapButton
                className="mt-4"
                variant="secondary"
                icon="archive-solid"
                onClick={archiveGroupToggle}
                fullWidth
                hidden={
                  User?._session?.role?.name === "group_staff" ||
                  !canEditSettings ||
                  Group?.isArchived
                }
              >
                Archive Program
              </SnapButton>
              <SnapButton
                className="mt-4 mb-9"
                variant="tertiary"
                icon="duplicate-solid"
                onClick={copyGroupToggle}
                fullWidth
                hidden={
                  User?._session?.role?.name === "group_staff" ||
                  !canEditSettings ||
                  Group?.isArchived
                }
              >
                Copy Program
              </SnapButton>
            </div>
          </SnapActionSheet>
        )}
        <CopyGroup
          copyGroupOpen={copyGroupOpen}
          copyGroupToggle={copyGroupToggle}
          selectedGroup={Group.activeGroup}
        />
        <ArchiveGroup
          archiveOpen={archiveGroupOpen}
          archiveToggle={archiveGroupToggle}
          selectedGroup={Group.activeGroup}
        />
        {addSeasonOpen && (
          <AddNewSeason
            selectedGroup={Group.activeGroup!}
            setHasSeasonErrors={setHasSeasonErrors}
            hasSeasonErrors={hasSeasonErrors}
            addSeasonOpen={addSeasonOpen}
            addSeasonToggle={addSeasonToggle}
          />
        )}
      </div>
    </div>
  );
}

function SeasonValidate(form: Partial<GroupSettings>): SeasonErrorType {
  const error: SeasonErrorType = {
    seasonStart: false,
    seasonEnd: false,
    seasonDate: false,
    messages: [],
  };
  if (!form) return error;
  if (isNullOrEmpty(form?.seasonStartDate)) {
    error.seasonStart = true;
  }
  if (isNullOrEmpty(form?.seasonEndDate)) {
    error.seasonEnd = true;
  }
  if (
    new Date(form?.seasonStartDate ?? "") > new Date(form?.seasonEndDate ?? "")
  ) {
    error.seasonDate = true;
  }
  if (error.seasonStart || error.seasonEnd) {
    error.messages.push("Please fill in all the input fields");
  }
  if (error.seasonDate) {
    error.messages.push("End Date cannot be before Start Date");
  }
  return error;
}
function GroupValidate(groupName: string): GroupErrorType {
  const error: GroupErrorType = {
    groupName: false,
    messages: [],
  };
  if (emptyStringCheck(groupName)) {
    error.groupName = true;
    error.messages.push("Group name can not be empty");
  }
  return error;
}

export default Settings;
