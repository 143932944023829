import { useMutation } from "@apollo/client";
import DisplayContext from "context/display-context";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import { SpendAccountLimitsResponse } from "graphql/generated";
import { PATCH_GROUP_CHECK_DEPOSIT } from "graphql/mutations/group";
import { PATCH_ORG_CHECK_DEPOSIT } from "graphql/mutations/organization";
import { FormatMoney } from "helpers/format-money";
import { useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import UnitComponentGenerator from "unit/unit-component-generator";
import {
  SnapAlert,
  SnapAlertDescription,
  SnapAlertTitle,
  SnapIcon,
} from "suit";

function Deposit() {
  const programContext = useContext(ProgramContext);
  const displayContext = useContext(DisplayContext);
  const groupContext = useContext(GroupContext);
  const [limits, setLimits] = useState<
    SpendAccountLimitsResponse | undefined
  >();
  const [PATCH_CHECK_DEPOSIT] = useMutation(
    !!groupContext?.activeGroup
      ? PATCH_GROUP_CHECK_DEPOSIT
      : PATCH_ORG_CHECK_DEPOSIT
  );
  useEffect(() => {
    if (!!groupContext?.activeGroup) {
      setLimits(groupContext?.accountLimits);
    } else {
      setLimits(programContext?.accountLimits);
    }
  }, [
    groupContext?.accountLimits,
    groupContext?.activeGroup,
    programContext?.accountLimits,
  ]);
  useEffect(() => {
    window.addEventListener("unitCheckDepositCreated", async function (e: any) {
      e.detail
        .then(function (data: any) {
          let input = !!groupContext?.activeGroup
            ? {
                checkDepositId: data.data.id,
                groupId: groupContext.activeGroup.id,
              }
            : {
                checkDepositId: data.data.id,
              };
          PATCH_CHECK_DEPOSIT({
            variables: {
              input,
            },
          });
        })
        .catch((e: any) => {
          console.log(e);
        });
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="wrapper z-10 sticky">
      <div className="flex w-full px-6 py-2 bg-white">
        <p className="mr-auto font-bold text-base capitalize">Deposit Check</p>
        <span
          className={`text-[#64748B] hover:text-gray-800 inline-block leading-none cursor-pointer`}
          onClick={() =>
            displayContext?.setIsDepositCheckOpen(
              !displayContext.isDepositCheckOpen
            )
          }
        >
          <SnapIcon icon={"x-solid"} />
        </span>
      </div>
      <div className="card mt-0">
        <SnapAlert type="warning">
          <SnapAlertTitle>Online deposit requirements:</SnapAlertTitle>
          <SnapAlertDescription>
            <ul>
              <li>Sign the check</li>
              <li className="font-bold">
                Make check payable to: {displayContext?.programName}
              </li>
              <li className="font-bold">
                Endorse the check with: For mobile deposit at Thread Bank
              </li>
            </ul>
          </SnapAlertDescription>
        </SnapAlert>
        <div className="lg:flex mt-6">
          <p className="text-sm font-medium">
            Mobile Deposits cannot exceed daily limit of{" "}
            <span className="font-bold">
              {FormatMoney(limits?.checkDeposit?.limits.daily || 1_000)}
            </span>{" "}
            or monthly limit of{" "}
            <span className="font-bold">
              {FormatMoney(limits?.checkDeposit?.limits.monthly || 20_000)}
            </span>
          </p>
          <a
            className="text-xs self-center text-blue-600 font-medium lg:ml-4"
            href="https://helpdesk.snapraise.com/support-center/depositing-a-check-to-your-snap-spend-account"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about Mobile Deposit limits.
          </a>
        </div>
        <Divider isVisibleOnMobile />
        <UnitComponentGenerator
          type={"check-deposit"}
          data={{
            accountId: displayContext?.unitAccount,
          }}
        />
      </div>
    </div>
  );
}

export default Deposit;
