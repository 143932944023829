import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import { FormatMoney } from "helpers/format-money";
import InputMask from "shared-components/input-mask";
import Tooltip from "shared-components/tooltip";
import {
  SnapAlert,
  SnapAlertTitle,
  SnapIcon,
  SnapInput,
  SnapSelectMenu,
  SnapLink,
} from "suit";
import { sendCheckErrors } from "types/errors";

type MakePaymentProps = {
  dailyLimit: number;
  tooltipToggle: () => void;
  tooltipOpen: boolean;
  payeeOptions: SnapSelectMenuOption[];
  setPayeeOptions: React.Dispatch<React.SetStateAction<SnapSelectMenuOption[]>>;
  setSendCheckError: React.Dispatch<React.SetStateAction<sendCheckErrors>>;
  sendCheckErrors: sendCheckErrors;
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  setMemo: React.Dispatch<React.SetStateAction<string>>;
  memo: string;
  setNote: React.Dispatch<React.SetStateAction<string>>;
};

function MakePayment({
  dailyLimit,
  tooltipToggle,
  tooltipOpen,
  payeeOptions,
  setPayeeOptions,
  setSendCheckError,
  sendCheckErrors,
  amount,
  setAmount,
  setMemo,
  setNote,
}: MakePaymentProps) {
  return (
    <div className="modal-card ">
      <div className="text-sm">
        <SnapAlert type="info" className="hidden lg:inline">
          <SnapAlertTitle>
            Payees will receive checks within 8-10 business days. Checks are not
            reflected in your balance until they are cashed by the recipient.
            Checks are automatically voided if not cashed after 180 days.
          </SnapAlertTitle>
        </SnapAlert>
        <div className="lg:flex lg:mt-6 pb-4 border-b lg:border-b-0">
          {dailyLimit > 0 ? (
            <p className="font-medium">
              Check amount cannot exceed the daily limit of{" "}
              <span className="font-bold text-green-600">
                {FormatMoney(dailyLimit)}.
              </span>
            </p>
          ) : (
            <p className="font-medium">
              Check amount cannot exceed the daily limit. Contact support to
              determine what that amount is.
            </p>
          )}
          <div className="flex mt-4 lg:mt-0 lg:pl-4 ">
            <SnapLink
              text=" Learn more about sending checks."
              href="https://helpdesk.snapraise.com/support-center/sending-checks"
              target="_blank"
              className="text-blue-600 font-bold"
            />
            <div className="lg:hidden ml-auto relative">
              <SnapIcon
                icon="information-circle-solid"
                color="#3B82F6"
                size="sm"
                className="cursor-pointer"
                onClick={tooltipToggle}
              />
              <Tooltip
                tooltipOpen={tooltipOpen}
                tooltipToggle={tooltipToggle}
                className="w-72 -right-4 top-6"
                tooltipClassName="ml-60"
              >
                <p className="text-sm">
                  Mail checks to the payee you specify. Payees will receive
                  checks in 8-10 business days. Checks are automatically voided
                  if they are not cashed after 90 days, and funds will be
                  credited back to your account.
                </p>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:grid grid-cols-2 mt-4 gap-6">
        <div>
          <SnapSelectMenu
            label="Payee"
            helpText="Note: You are allowed up to 30 saved payees."
            placeholder="- Select Payee -"
            selectMenuOptions={payeeOptions}
            onSnap-select-menu-updated={(e) => {
              setPayeeOptions(e.detail);
              setSendCheckError({ ...sendCheckErrors, payeeError: false });
            }}
            error={sendCheckErrors.payeeError}
          />
        </div>
        <InputMask
          amount={amount}
          setAmount={setAmount}
          hasError={sendCheckErrors.amountError}
          onChange={() => {
            setSendCheckError({ ...sendCheckErrors, amountError: false });
          }}
        />
      </div>
      <div className="mt-4">
        <SnapInput
          _id={""}
          label="Memo"
          maxlength="39"
          helpText="This memo will appear on the check."
          displayCharCount={true}
          onSnap-input-change={(e) => {
            if (e.detail.target.value.length <= 39) {
              setMemo(e.detail.target.value);
              setSendCheckError({ ...sendCheckErrors, memoError: false });
            }
          }}
          onBlur={(e) => {
            if (e.target.value.length <= 40) {
              setMemo(e.target.value);
              setSendCheckError({ ...sendCheckErrors, memoError: false });
            }
          }}
          error={sendCheckErrors.memoError}
        />
      </div>
      <div className="mt-6">
        <SnapInput
          _id={""}
          label="Note"
          textarea
          maxlength="200"
          displayCharCount={true}
          onSnap-input-change={(e) => {
            setNote(e.detail.target.value);
          }}
          onBlur={(e) => setNote(e.target.value)}
        />
      </div>
    </div>
  );
}

export default MakePayment;
